console.log("init");



function handleMenuBehavior() {
  // Target only the elements within .style-2 that have the class .menu-item-has-children
  if ($(window).width() > 1024) {
    $('.style-2 ul .menu-item-has-children').hover(
      function() {
        // On hover, add the 'active' class
        $(this).addClass('active');
      }, function() {
        // On hover out, remove the 'active' class
        $(this).removeClass('active');
      }
    );
  }
}
$(document).ready(function() {
  handleMenuBehavior();

  // Re-run the function on window resize
  $(window).resize(function() {
    handleMenuBehavior();
  });
});

$(document).ready(function() {









  $(".style-2 ul .menu-item-has-children").each(function( index ) {
    // Get the title of the parent menu item
    let title = $(this).find("a").first().text();
    // Get the sub-menu
    let submenu = $(this).find(".sub-menu");

    // Create the submenu title element
    let submenuTitle = $("<div class='submenu-title'>" + title + "</div>");

    // Get the menu items inside the sub-menu
    let menuItems = submenu.find("li");

    // Calculate the split point
    let half = Math.ceil(menuItems.length / 2);

    // Wrap the first half in a new div (column1)
    let column1 = $("<div class='column column1'></div>").append(menuItems.slice(0, half));

    // Wrap the second half in another div (column2)
    let column2 = $("<div class='column column2'></div>").append(menuItems.slice(half));

    // Clear the original sub-menu
    submenu.empty();

    // Append the submenu title, then the two columns
    submenu.append(submenuTitle).append(column1).append(column2);



  });


  $(".style-1").each(function(index) {
    let submenu = $(this).find(".sub-menu");

    let menuItems = submenu.find("li");

    // Calculate the split point ensuring column1 has 2 more elements than column2
    let column1Count = Math.ceil((menuItems.length + 2) / 2);
    let column2Count = menuItems.length - column1Count;

    let column1 = $("<div class='column column1'></div>").append(menuItems.slice(0, column1Count));
    let column2 = $("<div class='column column2'></div>").append(menuItems.slice(column1Count, column1Count + column2Count));

    // Clear the original sub-menu
    submenu.empty();

    // Append the submenu title, then the two columns
    submenu.append(column1).append(column2);
  });



  $('.style-2 ul .menu-item-has-children').addClass("mobile-item");

  $('.style-2 ul .menu-item-has-children .sub-menu .menu-item').addClass("mobile-item-link");

  $('.style-2 ul .menu-item-has-children a').append("<div class='submenu-arrow'><img src='/wp-content/themes/carolina/images/arrow-next.png'></div>");

  $('.style-2 a').on('click', function(e) {
    e.preventDefault(); // Prevent the default anchor behavior
    window.location.href = $(this).attr('href'); // Redirect to the href attribute
  });

  if (window.location.href.indexOf("szpital-dla-dzieci-lux-med") > -1) {
    $(".changephonenumber.warszawa").text("+48 22 458 70 31");
    $(".changephonenumber.warszawa").parent().attr("href", "tel:+48224587031");
    let contact_item = $(".footer__contact").find(".changephonenumber").first();

    if(contact_item.attr("href") == "tel:+48223558200"){
      contact_item.attr("href", "tel:+48224587031")
      contact_item.text("+48 22 458 70 31");
    }
  }else {
    $.ajax({
      url: ajaxUrl,
      data: {
        action: 'get_phones'
      },
      method: 'POST',
      complete: function (response) {
      },
      success: function (response) {
        // console.log(response);
        $(".changephonenumber").each(function () {
          var tel = null;
          if ($(this).hasClass('warszawa')) {
            tel = response.warszawa;
          } else if ($(this).hasClass('gdansk')) {
            tel = response.gdansk;
          }


          // console.log(tel, $(this).hasClass('warszawa'), $(this).hasClass('gdansk'));

          if (!tel) return;
          var callto = (tel).replaceAll(" ", "").replaceAll("&nbsp;", "");

          if ($(this).attr("href")) {
            $(this).attr("href", "tel:" + callto).html(tel);
          } else if ($(this).parent().attr("href")) {
            $(this).html(tel).parent().attr("href", "tel:" + callto);
          }
          $(this).css({'opacity': 1});
        });
      }
    });
  }

  if($('#formTel').length) {
    $('#formTel').inputmask("999-999-999");
  }

  if($('#contactFormTel').length) {
    $('#contactFormTel').inputmask("999-999-999");
  }


})

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

if (window.devicePixelRatio === 1.25) {
  $("body").addClass("zoom_125");
} else if (window.devicePixelRatio > 1.25) {
}

function menuToggle() {
  let burger = $('.menu-toggle'),
    topBar = $('.topBar'),
    body = $('body'),
    headerCall = $('.headerCall'),
    headerInfo = $('.headerInfo'),
    headerClose = $('.headerClose');

  burger.on('click', function () {
    if (!burger.hasClass('is-active')) {
      burger.addClass('is-active');
      topBar.addClass('is-opened');
      body.addClass('is-opened');
      $('html').css('overflow-y', 'hidden');


      let h = 0;
      h = $(window).innerHeight() - $('.header.topBar').innerHeight() - $('#cookiebar').innerHeight();
      $('.mainMenuContainer').css('max-height', h);
      $('.mainMenuContainer').css('overflow-y', 'overlay');

      if (headerInfo.hasClass('active')) {
        headerInfo.removeClass('active');
      }
    } else {
      burger.removeClass('is-active');
      topBar.removeClass('is-opened');
      body.removeClass('is-opened');
      $('html').css('overflow-y', 'scroll');
    }
  });

  $(window).on('resize', function(){
    if ($(this)[0].innerWidth > 1023) {
      $('.mainMenuContainer').css('overflow-y', 'visible');
      burger.removeClass('is-active');
      topBar.removeClass('is-opened');
      body.removeClass('is-opened');
      $('html').css('overflow-y', 'scroll');
    } else {
      let h = 0;
      h = $(window).innerHeight() - $('.header.topBar').innerHeight();
      $('.mainMenuContainer').css('max-height', h);
      $('.mainMenuContainer').css('overflow-y', 'overlay');
    }
  })

  headerCall.on('click', function (e) {
    e.preventDefault();
    if (!headerInfo.hasClass('active')) {
      headerInfo.addClass('active');
      if ($(body).hasClass('is-opened')) {
        burger.removeClass('is-active');
        topBar.removeClass('is-opened');
        body.removeClass('is-opened');
        $('html').css('overflow-y', 'visible');
      }
    }
  });

  headerClose.on('click', function () {
    headerInfo.removeClass('active');
  })

  //$("#main-menu > li > .sub-menu > .menu-item-has-children").closest(".sub-menu").addClass("sub-menu-has-grandchildren");

  $("#main-menu > .menu-item-has-children > a ").on('click', function (e) {
    e.preventDefault();
  })

  //$('.sub-menu-has-grandchildren > li > a').on('click', function (e) {
    //e.preventDefault();
  //})

  if($('.current-menu-item').length) {
    $('#main-menu > .menu-item').addClass('active-current-menu-item');
  }
}

function subMenuToggle() {
  let currentWindowWidth = $(window).width();

  function showHide() {
    const menuItemHasChildren = $('.menu-item-has-children');

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).find('.sub-menu').stop(true).animate({
        'maxHeight': '0vh',
      }, 200, 'linear');
    } else {
      menuItemHasChildren.removeClass('active');
      menuItemHasChildren.find('.sub-menu').stop(true).animate({
        'maxHeight': '0vh',
      }, 200, 'linear');

      $(this).addClass('active');
      let totalHeight = $(this).find('.sub-menu li').map(function () {
        return $(this).outerHeight();
      }).get().reduce((acc, height) => acc + height, 0);

      const maxHeight = currentWindowWidth >= 1024 ? totalHeight : 2000;
      $(this).find('.sub-menu').stop(true).animate({
        'maxHeight': maxHeight + 'px',
      }, 200, 'linear');
    }
  }

  const menuItemHasChildren = $('.menu-item-has-children');

  function bindClickEvents() {
    if ($(window).width() <= 1023) {
      menuItemHasChildren.off('click').on('click', showHide);
      menuItemHasChildren.find('.sub-menu').css('maxHeight', '0'); // Reset the submenu for mobile view
    } else {
      menuItemHasChildren.off('click');
      menuItemHasChildren.removeClass('active');
      menuItemHasChildren.find('.sub-menu').css('maxHeight', ''); // Remove inline styles for desktop view
    }
  }

  // Initial binding of click events
  bindClickEvents();

  // Handle window resize
  $(window).on('resize', function () {
    currentWindowWidth = $(window).width();
    bindClickEvents();
  });
}





function searchBox() {
  const search = $('.search-box');
  const searchInputWrapper = $('.search-box__inputWrapper');
  search.on('click', function (e) {
    search.addClass('active');
    searchInputWrapper.find('#search').focus();
    searchInputWrapper.animate({
      opacity: 1,
    }, 200);
    $('.contact').animate({
      opacity: 0,
    }, 200);
  })

  function checkAndSubmit(searchInput) {
    if (searchInput.val() != '') {
      searchInput.parent('form').submit()
    } else {
      searchInput.attr('placeholder', 'Pole wyszukiwania nie może być puste');
      searchInput.addClass('error');
      setTimeout(function() {
        searchInput.attr('placeholder', 'Szukaj ..');
        searchInput.removeClass('error');
      }, 2000)
    }
  }

  searchInputWrapper.find('.search-icon').on('click', function(e) {
    e.preventDefault();
    checkAndSubmit($(e.target).closest('.search-box__inputWrapper').find('.search-input'));
  })

  $('.search-clear').on('click', function (e) {
    e.stopPropagation();
    $('#search').val('');
    search.removeClass('active');
    searchInputWrapper.css('opacity', 0);
    $('.contact').css('opacity', 1);
  })


  $(document).on('click', function (e) {
    if (!search.is(e.target) && search.has(e.target).length === 0 && $('.search-input').val().length === 0) {
      search.removeClass('active');
      searchInputWrapper.css('opacity', 0);
      $('.contact').css('opacity', 1);
    }
  })
}

function wcagChange() {
  const wcag = $('.wcag-item'),
    wcagFont = $('.wcag__font'),
    wcagContrast = $('.wcag__contrast');
  let fontCookie = localStorage.getItem('wcagFont'),
    contrastCookie = localStorage.getItem('wcagContrast');

  if (fontCookie) {
    $('html').addClass('wcagFont');
  }
  if (contrastCookie) {
    $('html').addClass('wcagContrast');
    wcagContrast.addClass('active');
  }

  wcag.on('click', function () {
    let thisAttr = $(this).data('wcag');
    if (!$('html').hasClass(thisAttr)) {
      $('html').addClass(thisAttr);
      $(this).addClass('active');
      localStorage.setItem(thisAttr, true);
    } else {
      $('html').removeClass(thisAttr);
      $(this).removeClass('active');
      localStorage.removeItem(thisAttr);
    }
  })
}

// function langSwitcher() {
//   const switcher = $('.lang-switcher');
//
//   if (switcher) {
//     switcher.on('click', function (e) {
//       $(this).toggleClass('is-active');
//     });
//     window.addEventListener('click', e => {
//       if (e.target.classList.contains('lang-switcher') || e.target.classList.contains('lang-item')) {
//       } else {
//         switcher.removeClass('is-active');
//       }
//     })
//   }
// }

function callBackPopup() {
  let close = $('.callbackClose'),
    icon = $('.callback-icon'),
    popup = $('.callbackPopup');

  icon.on('click', function () {
    popup.css('display', 'flex');
    $('html').addClass('overflow');
  })

  close.on('click', function () {
    $('.callbackMain').show();
    $('.callbackThanks').hide();
    $('.wpcf7-response-output').show();
    popup.hide();
    $('html').removeClass('overflow');
  })
}

function formAfterSent() {
  var wpcf7ElmCallBack = document.querySelector( '.callbackPopup' );
  if ( wpcf7ElmCallBack ) {
    wpcf7ElmCallBack.addEventListener( 'wpcf7mailsent', function( event ) {
      $('.callbackMain').slideUp(150);
      $('.callbackThanks').delay(250).slideDown(150);
      $('.wpcf7-response-output').hide();
    }, false );
  }

    var wpcf7ElmTestimonial = document.querySelector( '.testimonialForm' );
    if ( wpcf7ElmTestimonial ) {
      wpcf7ElmTestimonial.addEventListener( 'wpcf7mailsent', function( event ) {
        $(this).find('.image').css({ 'background-image' : '' });
        $('.testimonialMain').slideUp(150);
        $('.testimonialThanks').delay(250).slideDown(150);
        $('.wpcf7-response-output').hide();
      }, false );
    }
}

function orphans() {
  $('.orphan-chars, .wpb_wrapper :header, .wpb_wrapper p, .wpb_wrapper span, .wpb_wrapper li, .wpb_wrapper td, .wpb_wrapper th').not('.wpb_wrapper form span').each(function () {
    if( $(this).find('input') || $(this).find('select') || $(this).find('textarea') ) return;

    $(this).html($(this).html().replace(/\s([A-Za-z])\s([A-Za-z])\s/g, ' $1&nbsp;$2&nbsp;'));
    $(this).html($(this).html().replace(/\s([A-Za-z])\s/g, ' $1&nbsp;'));
    $(this).html($(this).html().replace(/\(([A-Za-z])\s/g, '($1&nbsp;'));
    $(this).html($(this).html().replace(/\)([A-Za-z])\s/g, ')$1&nbsp;'));
    $(this).html($(this).html().replace(/\s{1,}([0-9])\s{1,}([0-9]*)\s{1,}/g, '$1&nbsp;$2'));
    $(this).html($(this).html().replace(/(^|\s)([0-9])\s{1,}([0-9]{1,})\s/g, '$1$2&nbsp;$3'));
    $(this).html($(this).html().replace(/\s{1,}([0-9])\s([A-Za-z])\s{1,}/g, '$1&nbsp;$2'))
    $(this).html($(this).html().replace(/\sr.\s/g, '&nbsp;r. '));
    $(this).html($(this).html().replace(/\szł/g, '&nbsp;zł'));
    $(this).html($(this).html().replace(/\sPLN/g, '&nbsp;PLN'));
  });
}

function cityToggle() {
  let userCity = checkCityCookie('userCity');

  if( userCity === '') {
    userCity = 'warsaw';
    setCookie('userCity', userCity, 30);
    showCitySections(userCity);
  } else if( userCity !== 'warsaw' && userCity !== 'gdansk' && userCity != 'optimum' ) {
    userCity = 'warsaw';
    setCookie('userCity', userCity, 30);
    showCitySections(userCity);
  } else {
    showCitySections(userCity);
  }

  function setCookie(name, v, expDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
    let e = "expires="+d.toUTCString();
    document.cookie = `${name}=${v};${e};path=/`;
  }

  function checkCityCookie(cityCookie) {
    let city = getCookie(cityCookie);
    if (city != "") {
      return city;
    } else {
      return "warsaw";
    }
  }

  $('.btn-cityToggle').on('click', function() {
    let chosenCity = $(this).data('toggle-city');

    // Check if a city is chosen, if not default to Warsaw
    if (!chosenCity) {
      chosenCity = 'warsaw';
    }

    setCookie('userCity', chosenCity, 30);
    showCitySections(chosenCity);
  });

  function showCitySections(city) {
    $('[data-place]').hide();

    $('.btn-cityToggle').removeClass('toggled');
    $(`.btn-cityToggle[data-toggle-city="${city}"]`).addClass('toggled');

    $(`[data-place="${city}"]`).each(function (i, e) {
      $(this).show();
    })

    $(document).on('ready ajaxComplete', function () {
      $(`[data-place="${city}"]`).each(function (i, e) {
        $(this).show();
      })
    })
  }
}

function categoryToggle() {
  let city = getCookie('userCity');
  let allTabs = $('.tab');
  let userCityTabs = $(`[data-place="${city}"] .tab`);
  let btnToggleCity = $('.btn-cityToggle');
  let btnToggleCategory = '';

  if($(`[data-place="${city}"]`).length) {
    $(document).on('ready ajaxComplete', function () {
      // // Hide all of the tabs
      // allTabs.hide();
      // // Show tabs depends on user city
      // userCityTabs.show();
      //
      // btnToggleCategory = $(`[data-place="${city}"] [data-toggle-category='wszystkie']`);
      // btnToggleCategory.addClass('toggled');
    })

    $(document).on('click', btnToggleCity, function (e) {
      if (btnToggleCity.is(e.target)) {
        city = getCookie('userCity');
        allTabs = $('.tab');
        userCityTabs = $(`[data-place="${city}"] .tab`);
        allTabs.hide();
        userCityTabs.show();
        btnToggleCategory = $(`[data-place="${city}"] [data-toggle-category]`);
        btnToggleCategory.removeClass('toggled');
        btnToggleCategory = $(`[data-place="${city}"] [data-toggle-category='wszystkie']`);
        btnToggleCategory.addClass('toggled');
      }
    })

    $(document).on('click', btnToggleCategory, function (e) {
      if ($(`[data-place="${city}"] [data-toggle-category]`).is(e.target)) {
        city = getCookie('userCity');
        allTabs = $('.tab');
        btnToggleCategory = $(`[data-place="${city}"] [data-toggle-category]`);
        btnToggleCategory.removeClass('toggled');
        btnToggleCategory.each(function () {
          if ($(this).is(e.target)) {
            // $(this).addClass('toggled');
            $(`[data-toggle-category="${$(this).data('toggle-category')}"]`).addClass('toggled');
            if ($(this).data('toggle-category') !== 'wszystkie') {
              userCityTabs = $(`[data-place="${city}"] .tab[data-category="${$(this).data('toggle-category')}"]`);
              allTabs.hide();
              userCityTabs.show();
            } else {
              userCityTabs = $(`[data-place="${city}"] .tab`);
              allTabs.hide();
              userCityTabs.show();
            }
          }
        });
      }
    })
  } else {
    btnToggleCategory = $(`[data-toggle-category='wszystkie']`);
    btnToggleCategory.addClass('toggled');

    if (window.location.href.includes('zabiegi-i-operacje') && window.location.hash) {
      var category = window.location.hash.substring(1);
      var button = $('.btn-operationsToggle[data-toggle-category="' + category + '"]');
      var category_tab = $('.operations-tab[data-category="' + category + '"]');

      $(".btn-operationsToggle" ).removeClass("toggled");
      $(button).addClass("toggled");


      $(".operations-tab").hide();
      $(category_tab).show();
      $(category_tab).find(".operation-title").show();



    }


    let chosenTabs = '';
    let chosenTabScrollHeight = 0;
    $(document).on('click', btnToggleCategory, function (e) {
      if ($(`[data-toggle-category]`).is(e.target)) {
        allTabs = $('.tab');
        btnToggleCategory = $(`[data-toggle-category]`);
        btnToggleCategory.removeClass('toggled');
        btnToggleCategory.each(function () {
          if ($(this).is(e.target)) {
            $(this).addClass('toggled');
            if ($(this).data('toggle-category') !== 'wszystkie') {
              chosenTabs = $(`.tab[data-category="${$(this).data('toggle-category')}"]`);
              allTabs.hide();
              chosenTabs.show();
              if (chosenTabs.find('.operation-title').text().toLowerCase() == $(this).text().toLowerCase()) {
                chosenTabs.find('.operation-title').hide();
              }
            } else {
              chosenTabs = $(`.tab`);
              allTabs.hide();
              chosenTabs.show();
              chosenTabs.find('.operation-title').show();
            }
          }
        });
      }
    })
  }

  // Functions

  function calculateScrollHeight(tabs, initialScrollHeight) {
    let calculatedScrollHeight = initialScrollHeight;
    tabs.each(function() {
      calculatedScrollHeight += $(this).prop('scrollHeight');
    });
    return calculatedScrollHeight;
  }
}

function scrollToSection() {
  let scrollBtn = $('.scrollBtn');

  scrollBtn.each(function (i, el) {
    if (i !== 0) {
      let self = $(this);
      self.addClass('hidden');
      $('.section-title').each(function () {
        if ($(this).text() == self.text() && self.hasClass('hidden')) {
          self.show();
          self.removeClass('hidden');
          $(this).show();
          $(this).attr('id', self.data('target'));
          $('.scrollToSection').slideDown(400);
        } else if ($(this).is('#wizyty') ) {
          $(this).show();
        } else if (self.hasClass('hidden')) {
          self.hide();
        }
      })
    }
  });

  scrollBtn.on('click', function (e) {
    let menuH = $('.topBar').outerHeight() + 10;
    let dataTarget = $(this).data('target'),
      target = $('#' + dataTarget);
    if (target.length) {
      e.preventDefault();
      $('.scrollBtn').not($(this)).removeClass('active');
      $(this).addClass('active');
      $('html, body').animate({
        scrollTop: target.offset().top - menuH,
      }, 800);
      return false;
    }
  })
}

function showImagewAttachment() {
  function readURL(input, container) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        container.find('.image').css('background-image', `url(${e.target.result})`);
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  const imageInputContainer = $('.form__el--photo');
  imageInputContainer.find('.form__image').on('change', function () {
    readURL(this, imageInputContainer);
  });
}

function offerBoxSetEqualHeight() {
  let offerBoxes = $('.offer_boxes');
  function setBoxHeight() {
    offerBoxes.closest('.row').each(function (i, e) {
      let h = 0;

      let row = $(this);
      row.find('.offer_boxes').css('min-height', 0);
      row.find('.offer_boxes .item').css('min-height', 0);
      row.each(function() {
        let heights = [];
        $(this).find('.offer_boxes').each(function (ind, el) {
          $(this).children('.item').each(function(){
            heights.push($(this).innerHeight());
          })
          h = Math.max(...heights);
          row.find('.offer_boxes .item').css('min-height', h);
        });
      })
    });
  }

  setBoxHeight();

  $(window).on('resize', function () {
    setBoxHeight();
  })
}

function offerBoxTitlesEqualHeight() {
  let offerBoxes = $('.offer_boxes');
  function setTitleHeight() {
    offerBoxes.closest('.row').each(function (i, e) {
      let h = 0;
      let row = $(this);
      let heights = [];
      row.find('.box-title').css('min-height', '0');
      row.each(function() {
        $(this).find('.offer_boxes').not('.offer_boxes--onlyTitle').each(function(){
          $(this).children('.item').find('.box-title').each(function(){
            heights.push($(this).innerHeight());
          });
        })
        h = Math.max(...heights);
        $(this).find('.item').find('.box-title').css('min-height', h);
      })
    });
  }

  if (offerBoxes) {
    setTitleHeight();

    $(window).on('resize', function () {
      setTitleHeight();
    });
  }
}

function dividerHeight() {
  let divider = $('.divider');
  function setDividerHeight() {
    divider.each(function (i, e) {
      let elem = $(this),
          dHeight = elem.data('dheight')/10 + 'rem',
          tHeight = elem.data('theight')/10 + 'rem',
          mHeight = elem.data('mheight')/10 + 'rem';
      if ($( window ).width() >= 1281) {
        elem.css('padding-bottom', dHeight);
      } else if ($( window ).width() <= 1280 && $( window ).width() >= 768) {
        elem.css('padding-bottom', tHeight);
      } else {
        elem.css('padding-bottom', mHeight);
      }
    });
  }

  if (divider) {
    setDividerHeight();

    $(window).on('resize', function () {
      setDividerHeight();
    });
  }
}

function scrollToInvalidField() {
  document.addEventListener( 'wpcf7invalid', function( event ) {
    setTimeout( function() {
      $('html').stop().animate({
        scrollTop: $('.wpcf7-not-valid').eq(0).offset().top - $('.header.topBar').innerHeight(),
      }, 500, 'swing');
    }, 100);
  }, false );
}



function initMap() {
  let maps = document.querySelectorAll("[data-lat]");
  maps.forEach((item, i) => {
    let latitude = parseFloat(item.getAttribute('data-lat'));
    let longitude = parseFloat(item.getAttribute('data-lng'));
    let iconSrc = item.getAttribute('data-iconSrc');
    let address = {
      lat: latitude,
      lng: longitude
    }
    const map = new google.maps.Map(maps[i], {
      zoom: 15,
      center: address,
    });

    const marker = new google.maps.Marker({
      position: address,
      map: map,
      icon: iconSrc,
    });
  });
}

function makeRecaptcha() {
  // Since we're using CF7's recaptcha, we'll leverage their grecaptcha instance
  if (typeof grecaptcha !== 'undefined') {
    grecaptcha.ready(function() {
      const forms = document.querySelectorAll('form');
      forms.forEach(form => {
        const recaptchaInput = form.querySelector('[name="_wpcf7_recaptcha_response"]');
        if (recaptchaInput) {
          grecaptcha.execute(wpcf7_recaptcha.sitekey, {action: 'submit'})
            .then(function(token) {
              recaptchaInput.value = token;
            });
        }
      });
    });
  }
}

function loadListNews(){
  var loadNewsRequest = null;
  let wrap = $("#list-news-ajax");

  if (wrap.length > 0) {
    function loadNews(page, category){

      if (loadNewsRequest) {
        loadNewsRequest.abort();
        wrap.removeClass('loading');
      }

      if (wrap.hasClass('loading')) return false;

      wrap.addClass('loading');
      loadNewsRequest = $.post(ajaxUrl, {
        action: 'loadNews',
        language: language,
        page: page,
        category: category
      }, function (response) {
        if (response) {
          wrap.html(response).removeClass('loading');
          // $('.lazy').Lazy();
        }else{
          wrap.removeClass('loading');
        }
      });
    }
    $(function(){
      var hashCat = null;
      if( top.location.hash && top.location.hash.match("category") && $(".btn-newsToggle[data-category='"+hashCat+"']").length ) {
        hashCat = top.location.hash.replace("#category-", "");
        $(".btn-newsToggle[data-category='"+hashCat+"']").addClass('toggled').siblings().removeClass('toggled');
      }
      loadNews(1, hashCat);

      $(document).on("click", ".btn-newsToggle", function(event){
        event.preventDefault();
        var btn = $(this),
          category = btn.attr('data-category')
        ;
        if( btn.hasClass('toggled') ) return;
        btn.addClass('toggled').siblings().removeClass('toggled');
        top.location.hash = "category-" + category;

        loadNews(1, category);
      });

      $(document).on("click", ".news-pagination a", function (event) {
        event.preventDefault();

        let btn = $(this),
          page = btn.attr('data-page'),
          category = $(".list-news-wrapper button.active").attr('data-category');

        loadNews(page, category);
        $('html, body').animate({
          scrollTop: $(".list-news-ajax").offset().top - 50
        }, 300);
      });
    });
  }


}

function loadListTestimonials(){
  var loadTestimonialsRequest = null;
  let wrap = $("#list-testimonials-ajax");

  if (wrap.length > 0) {
    function loadTestimonials(page){


      if (loadTestimonialsRequest) {
        loadTestimonialsRequest.abort();
        wrap.removeClass('loading');
      }

      if (wrap.hasClass('loading')) return false;

      wrap.addClass('loading');
      loadTestimonialsRequest = $.post(ajaxUrl, {
        action: 'loadTestimonials',
        language: language,
        page: page
      }, function (response) {
        if (response) {
          wrap.html(response).removeClass('loading');
          // $('.lazy').Lazy();
        }
      });
    }
    $(function(){
      loadTestimonials(1);

      $(document).on("click", ".testimonials-pagination a", function (event) {
        event.preventDefault();

        let btn = $(this),
          page = btn.attr('data-page')
        ;
        loadTestimonials(page);

        $('html, body').animate({
          scrollTop: $(".list-testimonials-ajax").offset().top - 50
        }, 300);
      });
    });
  }
}

function callbackDisplay() {
  let calbackIcon = $('.callback-icon');

  function checkIfDisplay() {
    $.post(ajaxUrl, {
      action: 'loadCallback',
      language: language,
    }, function (response) {
      if (response) {
        if(response == '1') {
          calbackIcon.addClass('show');
        } else {
          calbackIcon.removeClass('show');
        }
      }
    });
  }

  if (calbackIcon.length > 0) {
    checkIfDisplay();
    setInterval(function(){
      checkIfDisplay();
    }, 30000);
  }
}

function toggleSelectInput() {
  $(document).on('click', function(e){
    if($(e.target).is('.form__el--select')){
      if($(e.target).hasClass('active')){
        $(e.target).removeClass('active');
      } else {
        $(e.target).addClass('active');
      }
    } else {
      if($(e.target).is('select')){
        if($(e.target).closest('.form__el--select').hasClass('active')){
          $(e.target).closest('.form__el--select').removeClass('active');
        } else {
          $(e.target).closest('.form__el--select').addClass('active');
        }
      } else {
        $('.form__el--select').removeClass('active');
      }
    }
  })

  $(window).on('scroll', function(){
    $('.form__el--select').removeClass('active');
  })
}

// function table

window.addEventListener('load', function () {
  menuToggle();
  subMenuToggle();
  searchBox();
  wcagChange();
  langSwitcher();
  orphans();
  callBackPopup();
  formAfterSent();
  scrollToSection();
  showImagewAttachment();
  offerBoxTitlesEqualHeight();
  offerBoxSetEqualHeight();
  dividerHeight();
  cityToggle();
  scrollToInvalidField();
  categoryToggle();
  loadListNews();
  loadListTestimonials();
  callbackDisplay();
  toggleSelectInput();

  //if( $("form").length > 0 ){

    //var s = document.createElement("script");
    //s.type = "text/javascript";
    //s.src = "https://www.google.com/recaptcha/api.js?render=6Lcjdm0bAAAAANRhyWS4-BnpMSTqa_b4MQs8hI_B";
    //$("head").append(s);

    //setTimeout(makeRecaptcha, 1000);

  //}
});

$(document).ajaxComplete(function () {
  orphans();
})

$(function imageBoxes() {
    var imageBoxes = $('.imageBoxes');
    var $progressBar = imageBoxes.next().find(".progress");
    var $progressBarLabel = imageBoxes.next().find('.slider__label');

    imageBoxes.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

      $progressBar
        .css('background-size', calc + '% 100%')
        .attr('aria-valuenow', calc);
    });

    function setSlick(e) {
      e.slick({
        infinite: true,
        mobileFirst: true,
        slidesToShow: 2,
        appendArrows: $(".imageBoxes-nav__arrows"),
        appendDots: $(".imageBoxes-nav__dots"),
        variableWidth: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              variableWidth: true,
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 1023,
            settings: {
              variableWidth: true,
              slidesToShow: 3,
            }
          }
        ]
      });
    }

    setSlick(imageBoxes);

    let wcagFont = $('.wcag__font');

      wcagFont.on('click', function () {
        if(wcagFont.hasClass('active')) {
          let interval = setInterval(function () {
            if (!wcagFont.hasClass('active')) {
              clearInterval(interval)
              imageBoxes.slick('unslick');
              setSlick(imageBoxes);
            }
          }, 1);
        } else {
            let interval = setInterval(function () {
              if (wcagFont.hasClass('active')) {
                clearInterval(interval)
                imageBoxes.slick('unslick');
                setSlick(imageBoxes);
              }
            }, 1)
        }
      })
  });

$(function logos() {
  var logosSmall = $('.logos-slider-small');

  logosSmall.slick({
    mobileFirst: true,
    slidesToShow: 1,
    arrows: true,
    autoplay: true,
    duration: 2000,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 2
      }
    },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  });

  var logosBig = $('.logos-slider-big');
  var $progressBar = logosBig.next().find(".progress");
  var $progressBarLabel = logosBig.next().find('.slider__label');

  logosBig.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

    $progressBar
      .css('background-size', calc + '% 100%')
      .attr('aria-valuenow', calc);
  });

  logosBig.slick({
    mobileFirst: true,
    slidesToShow: 2,
    appendArrows: $(".logos-big-nav__arrows"),
    appendDots: $(".logos-big-nav__dots"),
    arrows: true,
    autoplay: true,
    duration: 2000,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 3
      }
    },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 5
        }
      }
    ]
  });
})

$(function photos() {

  var photos = $('.photos--slider'),
    photosFor = $('.photos--for-slider'),
    photosNav = $('.photos--nav-slider');

  photos.slick({
    mobileFirst: true,
    slidesToShow: 2,
    arrows: true,
    autoplay: true,
    duration: 2000,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 4
      }
    }]
  });

  photosFor.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.photos--nav-slider'
  });
  photosNav.slick({
    mobileFirst: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    asNavFor: '.photos--for-slider',
    arrows: true,
    focusOnSelect: true,
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 3
      }
    },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4
        }
      }
    ]
  });
})



$(function testimonials() {
  var testimonials = $('.testimonials-slider');
  var $progressBar = testimonials.next().find(".progress");
  var $progressBarLabel = testimonials.next().find('.slider__label');

  testimonials.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var calc = ((nextSlide) / (slick.slideCount - 1)) * 100;

    $progressBar
      .css('background-size', calc + '% 100%')
      .attr('aria-valuenow', calc);
  });

  testimonials.slick({
    mobileFirst: true,
    slidesToShow: 1,
    appendArrows: $(".testimonials-nav__arrows"),
    appendDots: $(".testimonials-nav__dots"),
    responsive: [{
      breakpoint: 767,
      settings: {
        variableWidth: true,
      }
    },
      {
        breakpoint: 1023,
        settings: {
          variableWidth: false,
          slidesToShow: 2
        }
      }
    ]
  });
})

$(function mainSlider() {
  $('.main-slider').each(function(){
    var speed = $(this).attr('data-speed');
    // console.log(speed);
    $(this).slick({
      autoplay: true,
      draggable: false,
      prewArrow: '',
      nextArrow: '',
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 1000,
      autoplaySpeed: speed
    })
  });
})

$(function trimText() {
  const btnMore = $('.short-text__btn-more');
  let texts = {};

  $('.short-text').each(function (i, el) {
    let text = $(this);
    text.addClass('hidden');
    texts[i] = {};
    texts[i]['long'] = text.text();
    texts[i]['height long'] = text[0].scrollHeight;
    texts[i]['number of words'] = parseInt(text.attr('data-wordsNumber'));
    texts[i]['short'] = texts[i]['long'].split(' ').slice(0, texts[i]['number of words']).join(' ');
    if (texts[i]['number of words'] >= texts[i]['long'].split(' ').length) {
      text.text(texts[i]['short']);
    } else {
      texts[i]['short'] += '...';
      text.text(texts[i]['short']);
    }
    texts[i]['height short'] = text[0].scrollHeight;
    text.css('max-height', texts[i]['height short'])
  })

  btnMore.each(function (i, el) {
    let text = $('.short-text');

    if (texts[i]['number of words'] >= texts[i]['long'].split(' ').length) {
      $(this).parent().css('display', 'none')
    }

    $(this).on('click', function () {
      if (text[i].classList.contains('shown')) {
        text[i].classList.remove('shown');
        text[i].classList.add('hidden');
        text[i].style.maxHeight = texts[i]['height short'] + 'px';
        setTimeout(function () {
          text[i].textContent = texts[i]['short'];
        }, 350);
        $(this).text(wordMore);
        $(this).removeClass('active');
      } else {
        text[i].classList.remove('hidden');
        text[i].classList.add('shown');
        text[i].textContent = texts[i]['long'];
        text[i].style.maxHeight = texts[i]['height long'] + 'px';
        $(this).text(wordLess);
        $(this).addClass('active');
      }
    })
  })
})

$(function cookiebar() {
  function loadCookiebar () {

  }

  function hideBar(direction) {

  }

  function setCookie(v, expDays) {

  }
})

$(function personPageUnfoldableList () {
  let lists = $('.person .wpb_wrapper').find('ul, ol').not('ul li ul, ol li ul, ol li ol, ul li ol');
  let i = 4;
  lists.each(function () {
    let list = $(this);
    $(this).find('li').each(function (index, el) {
      if (index > i) {
        $(this).hide();
        if (!list.find('.short-list__btn-wrapper').length > 0) {
          list.addClass('short-list hidden');
          list.append(`<div class="short-list__btn-wrapper"><button class="btn-more btn-more--small short-list__btn-more">${wordMore}</button></div>`);
        }
      }
    });
  });

  const btnMore = $('.short-list__btn-more');
  btnMore.each(function (n, el) {

    $(this).on('click', function () {
      let shortList = $(this).closest('.short-list');

      if (shortList.hasClass('hidden')) {
        shortList.removeClass('hidden');
        shortList.addClass('shown');
        $(this).text(wordLess);
        $(this).addClass('active');
        shortList.find('li').each(function (index, el) {
          if(index > i) {
            $(this).slideDown({
              duration: 200,
              start: function(){
                if ($(this).parent('ul').length) {
                  $(this).css('display', 'list-item')
                } else {
                  $(this).css('display', 'block')
                }
              }
            })
          }
        });
      } else {
        shortList.removeClass('shown');
        shortList.addClass('hidden');
        $(this).text(wordMore);
        $(this).removeClass('active');
        shortList.find('li').each(function (index, el) {
          if(index > i) {
            $(this).slideUp({
              duration: 200,
              start: function(){
                if ($(this).parent('ul').length) {
                  $(this).css('display', 'list-item')
                } else {
                  $(this).css('display', 'block')
                }
              }
            })
          }
        });
      }
    })
  })
});

$(function formMedicalDocumentation () {

  $(document).on("change", "#form_medical #verification_type", function(event){
    var select = $(this),
      val = select.val(),
      placeholder = select.find("option:selected").text() + "*",
      inputWrap = select.closest('.form-row').find('.hidden__el')
    ;
    if( val != ''){

      inputWrap.show().find('input').val("").attr('placeholder', placeholder);

    }else{
      inputWrap.hide();
    }
  });
  $(document).on("change", "#form_medical .form_medical_input_type", function(event){
    var value = $(this).val(),
      row = $(this).closest('.form-row')
    ;

    if( value == 'bl' ){
      row.find(".form_medical_input_results").closest('.form__el').hide();
    }else{
      row.find(".form_medical_input_results").closest('.form__el').show();
    }

  });

  $(document).on("click", "#form_medical .btn--remove", function(event){
    var message = $(this).attr("data-confirm"),
      row = $(this).closest('.form-row');

    if( (row.find("[name='types[]']").val() == '' && row.find("[name='dates[]']").val() == '' && row.find("[name='results[]']").val() == '' )|| confirm(message) ){
      row.remove();
    }
  });
  $(document).on("click", "#form_medical .btn--add", function(event){
    var row = $("#form_medical_results"),
      newRow = row.clone(),
      btn = $(this)
    ;

    newRow.find('input:not([disabled])').val("");
    newRow.find('select').val("");
    newRow.removeAttr('id');

    newRow.insertBefore(btn.closest('.form-row'));
  });

  $(document).on("submit", "#form_medical form", function(event) {
    event.preventDefault();
    var form = $(this);

    if (form.hasClass('sending')) return false;
    form.addClass('sending');

    form.find('.wpcf7-not-valid-tip').remove();
    form.find('.wpcf7-response-output').remove();



    // Get new recaptcha token before submission
    grecaptcha.ready(function() {
      grecaptcha.execute(wpcf7_recaptcha.sitekey, {action: 'submit'})
        .then(function(token) {
          const recaptchaInput = form.find('[name="_wpcf7_recaptcha_response"]');
          if (recaptchaInput.length) {
            recaptchaInput.val(token);
          }

          // Continue with your existing AJAX submission
          $.ajax({
            url: ajaxUrl,
            data: {
              action: 'form_medical',
              form: form.serialize(),
            },
            method: 'POST',
            complete: function(response) {
              form.removeClass('sending');
            },
            success: function(response) {
              if (response.success) {
                form.parent().html(response.message);
              } else {
                if (response.error && response.error != '') {
                  form.append(`<div class="wpcf7-response-output">${response.error}</div>`);
                }
                $.each(response.errors, function(key, error) {
                  form.find(`[name='${key}']`).closest('.form__el').append(`<div class="wpcf7-not-valid-tip">${error}</div>`);
                });
                // Refresh recaptcha token after error
                makeRecaptcha();
              }
            }
          });
        });
    });

    return false;
  });


  $(document).on("click", "#files-list .file_remove", function(event){
    $(this).closest('.file_item').remove();
  })

  $(document).on("change", "#files-list input[type=file]", function(event){
    var value = $(this).val(),
      row = $(this).closest('.file_item');
    if( value ){
      var filename = value.replace("C:\\fakepath\\", "");
      row.addClass('active').find('.file_name').text(filename);
    }else{
      row.remove();
    }
  });

  var counter = 0;
  $(document).on("click", "#form_conult_add_file", function(event){
    var wrap = $("#form_consult #files-list");

    $.each( wrap.find('.file_item'), function(k, item){
      if( $(this).find('input').val() == '' ){
        $(this).remove();
      }
    } );

    wrap.append("<div class='file_item file_item_"+counter+"'><span class='file_name'></span><span class='file_remove'>Usuń dodany plik</span><input type='file' name='attachments[]'></div>")
    wrap.find(".file_item_"+counter+" input").trigger('click');

    counter++;
  });
  $(document).on("submit", "#form_consult form", function(event) {
    event.preventDefault();
    var form = $(this);
    var formData = new FormData(form[0]);

    if (form.hasClass('sending')) return false;
    form.addClass('sending');

    form.find('.wpcf7-not-valid-tip').remove();
    form.find('.wpcf7-response-output').remove();



    // Get new recaptcha token before submission
    grecaptcha.ready(function() {
      grecaptcha.execute(wpcf7_recaptcha.sitekey, {action: 'submit'})
        .then(function(token) {
          const recaptchaInput = form.find('[name="_wpcf7_recaptcha_response"]');
          if (recaptchaInput.length) {
            recaptchaInput.val(token);
            formData.set('_wpcf7_recaptcha_response', token);
          }

          formData.append('action', 'form_consult');

          $.ajax({
            url: ajaxUrl,
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            method: 'POST',
            complete: function(response) {
              form.removeClass('sending');
            },
            success: function(response) {
              if (response.success) {
                form.parent().html(response.message);
              } else {
                if (response.error && response.error != '') {
                  form.append(`<div class="wpcf7-response-output">${response.error}</div>`);
                }
                $.each(response.errors, function(key, error) {
                  form.find(`[name='${key}']`).closest('.form__el').append(`<div class="wpcf7-not-valid-tip">${error}</div>`);
                });
                // Refresh recaptcha token after error
                makeRecaptcha();
              }
            }
          });
        });
    });

    return false;
  });

});

document.addEventListener('wpcf7mailsent', function(event) {
  makeRecaptcha();
}, false);



$(document).on("click", ".mobile-item", function (e){

  let submenu = $(this).find(".sub-menu");

  if(submenu.length > 0) {
    e.preventDefault();
    $(this).toggleClass("mobile-item-active");
    submenu.slideToggle("fast");
  }
});


$(document).on("click", ".mobile-item-link", function (){
  let link = $(this).find("a").attr("href");
  if (link) {
    window.location.href = link;
  }
});


jQuery(document).ready(function($) {
  // Find the menu items
  var menuItems = $('.style-2 ul li .sub-menu li');

  // Calculate the split point
  var half = Math.ceil(menuItems.length / 2);

  // Wrap the first half in a new div
  //var column1 = $('<div class="column column1"></div>').append(menuItems.slice(0, half));

  // Wrap the second half in another div
  //var column2 = $('<div class="column column2"></div>').append(menuItems.slice(half));

  //$('.sub-menu').empty().append(column1).append(column2);

  // Initial toggle based on URL hash
  if (window.location.href.includes('zabiegi-i-operacje') && window.location.hash) {
    toggleCategoryFromHash();
  }

  // Listen for hash changes and update accordingly
  $(window).on('hashchange', function() {
    if (window.location.href.includes('zabiegi-i-operacje')) {
      toggleCategoryFromHash();
    }
  });


});


// Function to show the correct tab based on the category hash
function toggleCategoryFromHash() {
  var category = window.location.hash.substring(1);
  var button = $('.btn-operationsToggle[data-toggle-category="' + category + '"]');
  var category_tab = $('.operations-tab[data-category="' + category + '"]');

  if (button.length && category_tab.length) {
    // Toggle the selected button
    $(".btn-operationsToggle").removeClass("toggled");
    $(button).addClass("toggled");

    // Show the relevant tab and hide others
    $(".operations-tab").hide();
    $(category_tab).show();
    $(category_tab).find(".operation-title").show();
  }
}
